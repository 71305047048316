// Pathify
import router from "@/router";
import {make} from "vuex-pathify";

// Data
const state = {
  user: {
    loggedIn: false,
    email: "",
  },
  layout: "login-layout",
  mini: true,
  theme: "warm-grey",
  creatorTab: null,
  payouttab: null,
  statisticTab: null,
  pagetitle: router.currentRoute.name,
  navigation: [
    {
      path: {
        name: "Dashboard",
      },
      icon: "mdi-view-dashboard",
      title: "navigation.dashboard",
      exact: true,
    },
    {
      path: {
        name: "Creators",
      },
      icon: "mdi-account-group",
      title: "navigation.creators",
      permission: ["menu:creator"],
      exact: false,
    },
    {
      path: {
        name: "Payouts",
      },
      icon: "mdi-currency-usd",
      title: "navigation.payouts",
      permission: ["menu:payout"],
      exact: false,
    },
    {
      path: {
        name: "Statistics",
      },
      icon: "mdi-chart-line-variant",
      title: "navigation.statistics",
      permission: ["menu:statistic"],
      exact: true,
    },
    {
      type: "divider",
      permission: ["menu:system"],
    },
    {
      path: {
        name: "System",
      },
      icon: "mdi-wrench",
      title: "navigation.system",
      permission: ["menu:system"],
      exact: true,
    },
  ],
  notify: {
    showNotification: false,
    message: null,
    title: null,
  },
};

const mutations = make.mutations(state);
const actions = {
  ...make.actions(state),
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
