import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/de";
import "dayjs/locale/en";
import localizedFormat from "dayjs/plugin/localizedFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);

export default dayjs;
