import i18n from "@/i18n";
import dayjs from "@/plugins/dayjs";
import {AxiosResponse} from "axios";
import Big from "big.js";

export const parseDate = (data?: Date, type?: string): string => {
  if (!data) {
    return "N/A";
  }
  dayjs.locale(i18n.locale);
  let shortFormat = "MM/DD";
  let shortMonth = "YY/MM";
  switch (i18n.locale) {
    case "de":
      shortFormat = "DD.MM";
      shortMonth = "MM.YY";
      break;
  }
  switch (type) {
    case "datetime":
      return `${dayjs(data).format("L")} ${dayjs(data).format("LT")}`;
    case "datetime24":
      return `${dayjs(data).format("L")} ${dayjs(data).format("HH:mm")}`;
    case "short":
      return dayjs(data).format(shortFormat);
    case "month":
      return dayjs(data).format("MMM YY");
    case "month-short":
      return dayjs(data).format(shortMonth);
    case "week":
      return dayjs(data).format("wo");
    case "day":
      return dayjs(data).format("Do");
    default:
      return dayjs(data).format("L");
  }
};

export const getStringDisplayWidth = (entry: string): number => {
  const test: HTMLElement = document.createElement("span");
  test.innerText = entry;
  document.body.append(test);
  const width = test.offsetWidth;
  document.body.lastChild?.remove();
  return width;
};

export const roundValues = (value: number) => {
  return new Big(value).round(2).toNumber();
};

export const formatNumbers = (value: number) => {
  return roundValues(value).toLocaleString(i18n.locale);
};

export const createAlertDetails = (
  res: AxiosResponse,
  translateNamespace: string
) => {
  let alertType = "error";
  let message: string;
  const max = 5;
  const data = res.data;
  switch (res.status) {
    case 200:
      switch (data.status) {
        case "ok":
          alertType = "success";
          message = i18n
            .t(`${translateNamespace}${data.code}` || data.message)
            .toString();
          break;
        default:
          alertType = "warning";
          message = `<p>${i18n
            .t(`${translateNamespace}${data.code}` || data.message)
            .toString()}`;
          for (const i in data.errors) {
            if (parseInt(i) === max) {
              break;
            }
            const error = data.errors[i];
            message += `<br/>${i18n.t(
              `${translateNamespace}${error.code}` || error.message,
              {creatorId: error.id}
            )}`;
          }
          message += `</p>`;
          break;
      }
      break;
    default:
      alertType = "error";
      if (!data.code) {
        message = data.message;
      } else {
        message = i18n
          .t(`${translateNamespace}${data.code}` || data.message)
          .toString();
        for (const i in data.errors) {
          if (parseInt(i) === max) {
            break;
          }
          const error = data.errors[i];
          message += `<br/>${i18n.t(
            error.message || `${translateNamespace}${error.code}`,
            {creatorId: error.id}
          )}`;
        }
        message += `</p>`;
      }
      break;
  }
  return {alertType, message};
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
