import {CreateUser, UpdateUser} from "@/interfaces/User";
import axios, {AxiosResponse} from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import {
  CheckPermissionResponse,
  CheckPermissionsResponse,
  Enable2FaResponse,
  LoggedInResponse,
  TwoFAStateResponse,
  UpdatePasswordResponse,
  ValidatedTokenResponse,
} from "../interfaces/Auth";

export const setAuthTokenCookie = (token: string, expires: number) => {
  Cookies.set("ccdat", token, {
    expires: dayjs().add(expires, "seconds").toDate(),
  });
};

export const login = async (
  email: string,
  // eslint-disable-next-line
  password: string
): Promise<AxiosResponse<LoggedInResponse>> => {
  const res = await axios("/v1/login", {
    method: "post",
    baseURL: process.env.VUE_APP_AUTH_URL,
    data: {
      email,
      password,
    },
  });
  return res;
};

export const validate = async (
  token: string
): Promise<AxiosResponse<ValidatedTokenResponse>> => {
  const res = await axios("/v1/validate", {
    method: "post",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
  });
  return res;
};

export const invalidate = async (token: string): Promise<AxiosResponse> => {
  const res = await axios("/v1/invalidate", {
    method: "post",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
  });
  return res;
};

export const checkPermission = async (
  token: string,
  permission: string
): Promise<AxiosResponse<CheckPermissionResponse>> => {
  const res = await axios("/v1/check-permission", {
    method: "post",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
    data: {
      permission,
    },
  });
  return res;
};

export const checkPermissions = async (
  token: string,
  permissions: string[]
): Promise<CheckPermissionsResponse> => {
  const res = await axios("/v1/check-permissions", {
    method: "post",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
    data: {
      permissions,
    },
  });
  return res.data;
};

export const loadAllUsers = async (token: string): Promise<AxiosResponse> => {
  const res = await axios("/v1/users", {
    method: "get",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
  });
  return res;
};

export const loadAllPermissions = async (
  token: string
): Promise<AxiosResponse> => {
  const res = await axios("/v1/permissions", {
    method: "get",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
  });
  return res;
};

export const updateUser = async (
  token: string,
  user: UpdateUser
): Promise<AxiosResponse> => {
  const data = user;
  const res = await axios(`/v1/users/${user.id}`, {
    method: "put",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
    data: data,
  });
  return res;
};

export const createUser = async (
  token: string,
  user: CreateUser
): Promise<AxiosResponse> => {
  const data = user;

  const res = await axios(`/v1/users`, {
    method: "post",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
    data: data,
  });
  return res;
};

export const get2FaState = async (
  token: string,
  email: string
): Promise<TwoFAStateResponse> => {
  const res = await axios(`/v1/users/2fa-state`, {
    method: "post",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
    data: {email},
  });
  return res.data;
};
// eslint-disable-next-line
export const enable2Fa = async (token: string): Promise<Enable2FaResponse> => {
  const res = await axios(`/v1/users/enable2fa`, {
    method: "get",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
  });
  return res.data;
};

export const activate2Fa = async (
  token: string,
  pin: string
): Promise<AxiosResponse> => {
  const res = await axios(`/v1/users/enable2fa`, {
    method: "post",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
    data: {token: pin},
  });
  return res;
};
// eslint-disable-next-line
export const validate2Fa = async (token: string, pin: string): Promise<any> => {
  const res = await axios(`/v1/users/validate2fa`, {
    method: "post",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
    data: {
      token: pin,
    },
  });
  return res.data;
};
// eslint-disable-next-line
export const disable2Fa = async (token: string): Promise<any> => {
  const res = await axios(`/v1/users/disable2fa`, {
    method: "delete",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
  });
  return res.data;
};

export const updatePassword = async (
  token: string,
  password: {current: string; new: string; confirm: string}
): Promise<UpdatePasswordResponse> => {
  const res = await axios(`/v1/users/update-password`, {
    method: "post",
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
      "x-token": token,
    },
    data: password,
  });
  return res.data;
};
