import Vue from "vue";
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from "vue-router";
import Cookies from "js-cookie";
import {setAuthTokenCookie, validate} from "@/utils/auth";
import {commit} from "vuex-pathify";
import {permissionMiddleware} from "@/utils/permissions";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  },
  {
    path: "/creators",
    name: "Creators",
    component: () =>
      import(/* webpackChunkName: "creators" */ "../views/Creators.vue"),
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      permissionMiddleware(to, from, next, ["menu:creator"]);
    },
  },
  {
    path: "/creators/:id",
    name: "Creator",
    component: () =>
      import(/* webpackChunkName: "creator" */ "../views/Creator.vue"),
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      permissionMiddleware(to, from, next, ["menu:creator"]);
    },
  },
  {
    path: "/payouts",
    component: () =>
      import(/* webpackChunkName: "payouts" */ "@/views/Payouts.vue"),
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      permissionMiddleware(to, from, next, ["menu:payout"]);
    },
    children: [
      {
        path: "",
        name: "Payouts",
        component: () =>
          import(
            /* webpackChunkName: "create-payouts" */ "@/components/payouts/CreatePayouts.vue"
          ),
      },
      {
        path: "all",
        name: "All Payouts",
        component: () =>
          import(
            /* webpackChunkName: "all-payouts" */ "@/components/payouts/AllPayouts.vue"
          ),
      },
      {
        path: "pending_earnings_no_mapping",
        name: "Pending Earnings Without Mapping",
        component: () =>
          import(
            /* webpackChunkName: "pending-earnings" */ "@/components/payouts/PendingEarnings.vue"
          ),
      },
      {
        path: "pending_earnings",
        name: "Pending Earnings Per Creator",
        component: () =>
          import(
            /* webpackChunkName: "pending-earnings-per-creator" */ "@/components/payouts/PendingEarningsPerCreator.vue"
          ),
      },
      {
        path: "excluded_from_adjustment_factor",
        name: "Excluded from adjustment factor",
        component: () =>
          import(
            /* webpackChunkName: "excluded-from-adjustment-factor" */ "@/components/payouts/ExcludedFromAdjustmentFactor.vue"
          ),
      },

      {
        path: "logs",
        name: "Payout Logs",
        component: () =>
          import(
            /* webpackChunkName: "payout-logs" */ "@/components/payouts/ErrorLogList.vue"
          ),
      },
    ],
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: () =>
      import(/* webpackChunkName: "statistics" */ "../views/Statistics.vue"),
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      permissionMiddleware(to, from, next, ["menu:statistic"]);
    },
  },
  {
    path: "/system",
    component: () =>
      import(/* webpackChunkName: "system" */ "../views/System.vue"),
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      permissionMiddleware(to, from, next, ["menu:system"]);
    },
    children: [
      {
        path: "",
        name: "System",
        component: () =>
          import(
            /* webpackChunkName: "users" */ "@/components/system/Users.vue"
          ),
        beforeEnter: (
          to: Route,
          from: Route,
          next: NavigationGuardNext<Vue>
        ) => {
          permissionMiddleware(to, from, next, ["system:user_management"]);
        },
      },
      {
        path: "imports",
        name: "Imports",
        component: () =>
          import(
            /* webpackChunkName: "imports" */ "@/components/system/Imports.vue"
          ),
        beforeEnter: (
          to: Route,
          from: Route,
          next: NavigationGuardNext<Vue>
        ) => {
          permissionMiddleware(to, from, next, ["system:imports"]);
        },
      },
      {
        path: "data-correction",
        name: "Data Correction",
        component: () =>
          import(
            /* webpackChunkName: "data-correction" */ "@/components/system/DataCorrection.vue"
          ),
        beforeEnter: (
          to: Route,
          from: Route,
          next: NavigationGuardNext<Vue>
        ) => {
          permissionMiddleware(to, from, next, ["system:payout_rollback"]);
        },
      },
      {
        path: "platform-config",
        name: "Platform Config",
        component: () =>
          import(
            /* webpackChunkName: "platform-config" */ "@/components/system/PlatformConfig.vue"
          ),
        beforeEnter: (
          to: Route,
          from: Route,
          next: NavigationGuardNext<Vue>
        ) => {
          permissionMiddleware(to, from, next, ["system:platform_config"]);
        },
      },
      {
        path: "uploaded_earnings_files",
        name: "Uploaded earnings files",
        component: () =>
          import(
            /* webpackChunkName: "payout-logs" */ "@/components/system/UploadedEarningsFiles.vue"
          ),
        beforeEnter: (
          to: Route,
          from: Route,
          next: NavigationGuardNext<Vue>
        ) => {
          permissionMiddleware(to, from, next, ["system:s3_earnings_files"]);
        },
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
  },
];
//TODO: add not found route

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const auth_token = Cookies.get("ccdat");
  // Check for Auth Token available
  if (auth_token) {
    try {
      const res = await validate(auth_token);
      setAuthTokenCookie(res.data.token, res.data.expires);
      commit("app/user", {loggedIn: true, email: res.data.email});
      commit("app/layout", "app-layout");
      // Is Auth Token valid and requested route is Login
      if (to.name === "Login") {
        next({
          path: "/",
        });
      } else {
        next();
      }
    } catch (error) {
      // Is Auth token not valid
      Cookies.remove("ccdat");
      commit("app/user", {loggedIn: false, email: null});
      commit("app/layout", "login-layout");
      next({
        name: "Login",
        query: {returnPath: to.path},
      });
    }
  } else {
    // Is no Auth Token available
    if (to.name !== "Login") {
      commit("app/layout", "login-layout");
      next({
        name: "Login",
        query: {returnPath: to.path},
      });
    } else {
      next();
    }
  }
});

export default router;
