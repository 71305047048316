import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import {ThemeOptions, VuetifyThemeVariant} from "vuetify/types/services/theme";

Vue.use(Vuetify);

export interface CCDThemeOptions extends ThemeOptions {
  themes?: {
    dark?: Partial<VuetifyThemeVariant>;
    light?: Partial<VuetifyThemeVariant>;
    dark1?: Partial<VuetifyThemeVariant>;
    dark2?: Partial<VuetifyThemeVariant>;
    dark3?: Partial<VuetifyThemeVariant>;
  };
}

export default new Vuetify({
  theme: {
    options: {customProperties: true},
    themes: {
      light: {
        primary: "#E040FB",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        level0: "#eeeeee",
        level1: "#ffffff",
        level2: "#ffffff",
        level3: "#ffffff",
        level4: "#ffffff",
        navigation: "#cccccc",
        "chart-mt80": "#00ff00",
        "chart-mt60": "#6fce02",
        "chart-mt40": "#ff9d00",
        "chart-mt20": "#fc4f00",
        "chart-mt0": "#ff0000",
      },
      dark1: {
        primary: "#E040FB",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        level0: "#19171D",
        level1: "#1A1D21",
        level2: "#242527",
        level3: "#393939",
        level4: "#272629",
        navigation: "#111214",
        "chart-mt80": "#00ff00",
        "chart-mt60": "#6fce02",
        "chart-mt40": "#ff9d00",
        "chart-mt20": "#fc4f00",
        "chart-mt0": "#ff0000",
      },
      "dark-blue": {
        primary: "#E040FB",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        level0: "#102a43",
        level1: "#334e68",
        level2: "#627d98",
        level3: "#829ab1",
        level4: "#9fb3c8",
        navigation: "#243b53",
        "chart-mt80": "#00ff00",
        "chart-mt60": "#6fce02",
        "chart-mt40": "#ff9d00",
        "chart-mt20": "#fc4f00",
        "chart-mt0": "#ff0000",
      },
      "warm-grey": {
        primary: "#E040FB",
        // primary: "#f00",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        level0: "#27241d",
        level1: "#504a40",
        level2: "#857f72",
        level3: "#a39e93",
        level4: "#b8b2a7",
        navigation: "#423d33",
        "chart-mt80": "#00ff00",
        "chart-mt60": "#6fce02",
        "chart-mt40": "#ff9d00",
        "chart-mt20": "#fc4f00",
        "chart-mt0": "#ff0000",
      },
      "cool-grey": {
        primary: "#E040FB",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        level0: "#1f2933",
        level1: "#3e4c59",
        level2: "#616e7c",
        level3: "#7b8794",
        level4: "#9aa5b1",
        navigation: "#323f4b",
        "chart-mt80": "#00ff00",
        "chart-mt60": "#6fce02",
        "chart-mt40": "#ff9d00",
        "chart-mt20": "#fc4f00",
        "chart-mt0": "#ff0000",
      },
    },
  } as CCDThemeOptions,
});
