import Cookies from "js-cookie";
import {NavigationGuardNext, Route} from "vue-router";
import {checkPermissions} from "./auth";

export const hasPermission = (
  permissions: string[],
  permission: string
): boolean => {
  return permissions.includes(permission);
};
export const hasOnePermission = (
  setedPermissions: string[],
  permissions: string[]
): boolean => {
  let checked = false;
  permissions.forEach((permission) => {
    checked = checked || hasPermission(setedPermissions, permission);
  });
  return checked;
};

export const countPermissions = (
  setedPermissions: string[],
  permissions: string[]
): number => {
  let cnt = 0;
  permissions.forEach((permission) => {
    if (hasPermission(setedPermissions, permission)) cnt++;
  });

  return cnt;
};

export const permissionMiddleware = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>,
  permissions: string[]
): void => {
  checkPermissions(Cookies.get("ccdat") as string, permissions).then((data) => {
    if (data.result.length === 0) {
      next({name: "Dashboard"});
    } else {
      data.result.map((e: {checked: boolean; permission: string}) => {
        if (e.checked === true) {
          next();
        } else {
          next({name: "Dashboard"});
        }
      });
    }
  });
};
